<script>
	import GlobalVue from '@helper/Global.vue';
	import Gen from '@helper/Gen';
	export default {
		extends: GlobalVue,
		data() {
			return {}
		},
		computed:{
			yearcopy(){
				return Gen.yearCopy(this.web.yearcopy)
			}
		},
		mounted() {},
		methods: {}
	};
</script>
<template>
	<footer id="footer">
		<div id="copyrights">
			<div class="container clearfix">
				<div class="row justify-content-center">
					<div class="col-md-12">
						<div class="copyrights-menu copyright-links clearfix">
							<router-link :to="{name:'ProfilYayasan'}">Profil Yayasan</router-link>/
							<router-link :to="{name:'Program'}">Program</router-link>/
							<router-link :to="{name:'News'}">Berita</router-link>/
							<router-link :to="{name:'Contact'}">Kontak Kami</router-link>/
							<router-link :to="{name:'PrivacyPolicy'}">Privacy Policy</router-link>/
							<router-link :to="{name:'TermCondition'}">Term &amp; Condition</router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="copyright_txt">
							<p>	Copyrights &copy; 2020  by Visi Viyata Lestari.<br></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>